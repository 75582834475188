@media screen and (max-width: 800px){


    .p-md-1{padding: 0.5em}
    .p-md-2{padding: 1em}
    .p-md-3{padding: 1.5em}
    .p-md-4{padding: 2em}

    .py-md-1{padding-top: 0.5em; padding-bottom: 0.5em}
    .py-md-2{padding-top: 1em; padding-bottom: 1em}
    .py-md-3{padding-top: 1.5em; padding-bottom: 1.5em}
    .py-md-4{padding-top: 2em; padding-bottom: 2em}

    .px-md-1{padding-left: 0.5em; padding-right: 0.5em}
    .px-md-2{padding-left: 1em; padding-right: 1em}
    .px-md-3{padding-left: 1.5em; padding-right: 1.5em}
    .px-md-4{padding-left: 2em; padding-right: 2em}

    .px-md-10{
        padding-left: 10px;
        padding-right: 10px;
    }
    .px-md-20{
        padding-left: 20px;
        padding-right: 20px;
    }

    .py-md-10{
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .py-md-20{
        padding-top: 20px;
        padding-bottom: 20px;
    }


    .flex-md-column {
        flex-direction: column !important;
    }

    /*===========================*/
    .profile_full_info { padding: 50px 0 0 0; margin-top: 0}
    .user_social_links { float:none; margin: 10px 0 0 0}
    .profile_func {max-width: 500px; width:auto; float: none; display: flex}
    .profile_func li { margin: 0; width: 50%; padding: 7px}

    .prof__top {  display: flex;  flex-wrap: nowrap;  width: 100%;  float: none;  padding: 10px}

    .profile_blog {
        padding-right: 0;
        width: 100%;
    }
    .profile_sidebar{
        display: none;
    }
    .profile_content{
        padding: 0;
    }
    /*===========================*/

    .fullRouteContainer{
        padding: 0;
    }

    .fullRouteContent{
        flex-direction: column-reverse;
    }

    .fullRouteContent .routeSidebar{
        flex: none;
    }

    .fullRouteContent .routeBlocksStats .item{
        padding: 6px 10px;
        width: calc(50% - 1em);
    }

    .fullRouteContent .routeBlocksStats .item:nth-child(even){
        border:none;
    }

    .fullRouteContent .routeBlocksStats{
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 1em;
    }

    .routePreview-top {
        padding: 20px 0;
    }

    /*===========================*/
    .bikes-items .short_bike{
        width: 100%;
        margin-right: 0 !important;
        border-radius: 1rem;
    }
    .bikes-items{
        margin-right: 0;
        padding: 1rem;
    }
    .bikes_recbikelogs {
        margin-right: 0;
        padding: 0;
    }
    .bikes_recbikelogs > .items > .rect_ovrl {
        width: 100%;
        padding-right: 0;
    }
    /*===========================*/

    #page_description{
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
    .page_description_show_more{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .pagebox {
        padding: 1rem 0;
    }
    .pagebox.auth_form_block{
        padding-top: 40px;
    }

    .footer__app{
        display: block !important;
    }

    .profile_foto-overlay {width: 80px;}
    .profile_foto {width: 80px; height: 80px;  bottom: -20px}

    .footer__copyrite{
        justify-content: center;
        max-width: 90%;
        margin:auto;
        padding: 10px 0;
        line-height: 30px;
    }

    .empty_block {
        width: 100%;
    }

    /*.n_post__header > a{padding: 10px}
    .n_news__header{padding: 15px 15px 0 15px}
    .n_post__main{padding: 10px 0 0 0}
    .n_post__bottom{padding: 10px}
    .n_news__bottom{padding: 15px 10px 15px 10px}
    .n_story__block+.n_story__block{margin-top: 10px}
    .n_post_content > .bikelogs_tags{
        padding-top: 10px;
    }
    .n_post__main>a>div+div, .n_post__main>div+div{
        padding-top: 10px;
        word-break: break-word;
    }
    .n_post__title, .n_post__text{
        padding-left: 10px;
        padding-right: 10px;
    }
    p.n_story__block{
        padding-left: 10px;
        padding-right: 10px;
    }*/

    .relative_post-overlay{
        width: 50%;
    }
    .relative_post-preview {
        height: 150px;
    }

    .login_btn {display: none}

    .speedbar { border-bottom: 1px solid #ebebeb; border-radius: 0 0 10px 10px}

    .header .full { padding: 0 }
    .full{padding: 0}

    .post__bottom > span { margin-right: 10px}

    .last_func,
    .cnt_m { display: none; }

    .bikelog__full {padding: 10px 10px 0 10px}


    .prof__top-photo{width: 120px}
    .profile_full_cover, .profile_full_cover:before {border-radius: 0}

    span.zoom {display:none}

    .p_user { width: 50%}
    .bike__descr { display:block}
    .bike__func {width: 100%;border: 0;clear: both;display: block}

    .bikes_list_for_news { flex-wrap:wrap}
    .bikes_list_for_news .bike_prev {width: 50%}

    .bikes_recbikelogs > .items{
        gap: 1rem;
    }

    .bikes_recbikelogs .rect_bl{
        margin-bottom: 0;
    }

    .bikes_recbikelogs > .items > .rect_ovrl{
        width: 100%;
    }

    .bikes_recbikelogs > .items > .rect_ovrl:nth-child(2n+2) {
        padding-right: 0;
    }

    .online_status {
        border-width: 2px;
        top: 54px;
        left: 54px;
    }

    .form_item-container {
        max-width: 90%;
    }
    .form-item__spacer {
        margin: 50px -4%;
    }

    .s_event__header{
        padding: 15px;
    }

    .s_event{
        padding: 0;
        margin: 0;
        flex-direction: column;
    }
    .s_event-preview .prev_img{
        display: none;
    }
    .s_event-preview{
        height: auto;
        flex: 1 1 auto;
        width: 100%;
        margin-bottom: 20px;
    }
    .s_event-preview .prev_img-full{
        display: block;
    }
    .s_event-preview .prev_img-full > img{
        width: 100%;
    }

    .s_event-info {
        padding: 0 20px 20px 20px;
        width: 100%;
        text-align: center;
    }

    .s_event-info > .event_name{
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--d800);
        font-size: 20px;
    }

    .s_event-info > .event_stat{
        display: none;
    }

    .s_event-info > .event_date{
        font-size: 16px;
    }

    .s_event-info > .event_loc{
        font-size: 18px;
    }

    .fe-btnsfunc{
        flex-direction: column;
        align-items: start;
    }
    .fe-btnsfunc .share-btns_container{
        margin-top: 14px;
    }

    .fullNewsContainer{
        padding: 0;
    }

    .news_search{
        padding: 0;
        flex-direction: column-reverse;
        gap: 20px
    }

    .eventsSearchForm-filter .dropdown_button{
        margin-right: 10px;
    }


    .settingsContainer.flex-row{
        flex-direction: column-reverse;
    }

    .modeObjects{
        max-width: none;
    }

    .addPostFormItem{
        padding: 0 10px;
    }

    .form_item-container{
        padding: 20px 0;
    }

    .addPostFormBottom {
        padding: 5px 10px;
    }

    .addPostFormBottom #saveButton{
        padding: 0 20px;
    }

    .mapSidebar{
        right: 0;
        bottom: -100%;
        animation: slideToBottomAnimation 0.3s forwards;
        border-left: 0;
    }

    .mapSidebar.active{
        animation: slideFromBottomAnimation 0.3s forwards;
    }

    .mapSidebar_eventView{
        position: absolute;
        top: auto;
        bottom: 0;
        height: 50%;
        width: 100%;
        background: var(--d900);
        border-radius: 1em 1em 0 0;
    }

    .sheet .draggable-thumb{
        display: block;
    }



    .full_business__servicesContainer > div{
        flex: 0 0 calc(50% - 1em);
    }

    .full_business__advantagesContainer > div {
        flex: 0 0 calc(50% - 1em);
    }

    .businessContacts{
        flex-direction: column;
        padding: 2em 1em;
    }

    .businessRatingFull{
        width: 100%;
    }

    .businessRatingFull-rateUser {
        padding-left: 0;
        margin: 1em;
        padding-top: 1em;
        border-left: 0;
        border-top: 1px solid var(--d700);
        width: 100%;
    }

    .businessRatingFull-ratingMore .rateStar {
        font-size: 26px;
    }




    .routePreviewHorizontal {
        grid-template-columns: 1fr;
        grid-template-areas:
                "rph-header"
                "rph-preview"
                "rph-profile"
                "rph-description"
                "rph-bottom";
        padding-right: 0;
    }
    .routePreviewHorizontal-preview{
        min-height: 200px
    }
    .routePreviewHorizontal-header{
        padding: 16px 16px 0 16px;
        display: flex;
        justify-content: space-between;
    }
    .routePreviewHorizontal-profile{
        padding: 0 16px;
    }
    .routePreviewHorizontal-bottom{
        padding: 0 16px 16px 16px;
        flex-direction: column;
        gap: 16px;
    }
    .routePreviewHorizontal-description{
        padding: 0 18px;
    }
    .routePreviewHorizontal-bottom_stats{
        flex: 1 1 auto;
        display: flex;
        width: 100%;
    }
    .routePreviewHorizontal-bottom_stats .routeBlocksStats{
        width: 100%;
        justify-content: center;
    }
    .routePreviewHorizontal-bottom_roadsType{
        flex: 0 0 auto
    }





    .settingsContainer-sidebar{
        position: fixed;
        right: -100%;
        top: 53px;
        width: 100%;
        height: 100vh;
        z-index: 99;
        margin: 0;
    }

    .settingsContainer-sidebar.active{
        right: 0;
    }

    .settingsContainer-menu{
        height: 100%;
        border-radius: 0;
    }

    .settingsShowSidebarBtn{
        display: flex;
        position: fixed;
        top: 72px;
        right: 0;
        background: var(--d700);
        border-radius: 10px 0 0 10px;
        width: 40px;
        height: 40px;
        z-index: 100;
        justify-content: center;
        align-items: center;
        font-size: 22px;
    }

    .garageItems{
        padding: 20px 0;
        gap: 1em;
    }

    .garage_item{
        flex-direction: column;
    }

    .garage_item__previewContainer{
        width: 100%;
        height: 200px;
    }

    .garage_content{
        width: 100%;
        float: none;
    }

    .garage_sidebar{
        display: none;
    }




    /*============= подписки ================*/

    .billingList-item.head{
        display: none;
    }

    .billingList-item{
        flex-direction: column;
        font-size: 1.2em;
        background: var(--d700);
    }

    .billingList-item.hover{
        color: white;
    }

    .billingList-item .amount{
        font-size: 2em;
    }

    .billingList-item .amount .cents{
        font-size: 0.7em;
    }

    .billingList-item div.top,
    .billingList-item div.bottom{
        justify-content: space-between;
        width: 100%;
    }

    .billingList-item div.top > div,
    .billingList-item div.bottom > div{
        flex: none;
    }

    .shortSubscription-content{
        flex-direction: column;
        gap: 1em;
    }

    .shortSubscription-content__card{
        flex-direction: row;
        padding: 0;
    }

    /*=======================================*/


}